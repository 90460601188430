import constant from "./constants";
import { getToken, logout } from "./dataService";

export var getAllOrg = (startIndex, limit, filter, active) => {
    return fetch(constant.API_URL + '/api/organization-billing/all', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken(), startIndex: startIndex, limit: limit, filter: filter, active: active }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var getActiveOrg = (page_no, filter) => {
  return fetch(constant.API_URL + "/api/organization-billing/active", {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: getToken(), page: page_no, limit: 10, filter: filter },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return error;
      }
    );
};

export var getArchiveOrg = (page_no, filter) => {
  return fetch(constant.API_URL + "/api/organization-billing/archive", {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: getToken(), page: page_no, limit: 10, filter: filter },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        console.log(result);
        return result;
      },
      (error) => {
        return error;
      }
    );
};
export var getOrganisationsBillings = (page_no, filtervalue, value, clientNameFilterValue, statusFilterValue) => {
  return fetch(constant.API_URL + "/api/organization-billing/billing", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
      page: page_no,
      filter: filtervalue,
      clientName: clientNameFilterValue || " ",
      statusFilter: statusFilterValue,
      value: value,
      limit: 10,
    },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        // console.log(result)
        return result;
      },
      (error) => {
        return error;
      }
    );
};

export var getOrganisationsCollection = (type, searchTerm, page, org_id) => {
  return fetch(constant.API_URL + "/api/organization-billing/collection/" + org_id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
      type: type,
      searchTerm: searchTerm,
      page: page,
    },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        // console.log(result)
        return result;
      },
      (error) => {
        return error;
      }
    );
};

export var getTicker = (org_id) => {
  return fetch(constant.API_URL + "/api/organization-billing/ticker/" + org_id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
    },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return error;
      }
    );
};

export var getOrganisationsClients = (page_no, filter, value) => {
  // console.log(value)
  return fetch(constant.API_URL + "/api/organization-billing/clients", {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: getToken(), page: page_no, limit: 10, filter: filter, value: value },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        // console.log(result)
        return result;
      },
      (error) => {
        return error;
      }
    );
};

export var deleteOrganisationsBillings = (id) => {
  return fetch(constant.API_URL + "/api/organization-billing/" + id, {
    method: "DELETE",
    headers: { "Content-Type": "application/json", Authorization: getToken() },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return error;
      }
    );
};

export var bulkDeleteOrganisationsBillings = (id) => {
  return fetch(constant.API_URL + "/api/organization-billing/bulk-delete", {
    method: "DELETE",
    body: JSON.stringify(id),
    headers: { "Content-Type": "application/json", Authorization: getToken() },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return error;
      }
    );
};

export var bulkUpdateOrganisationsBillings = (obj) => {
  return fetch(constant.API_URL + "/api/organization-billing/bulk-update", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: { "Content-Type": "application/json", Authorization: getToken() },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return error;
      }
    );
};

export var getRolePermissionAPI = (id) => {
  return fetch(constant.API_URL + "/api/role-permission-v2/" + id, {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: getToken() },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return error;
      }
    );
};

export var addUpdateRolePermissionAPI = (data) => {
  return fetch(constant.API_URL + "/api/role-permission-v2", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json", Authorization: getToken() },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return error;
      }
    );
};

export var upsertRolePermissionAPI = (data) => {
  return fetch(constant.API_URL + "/api/role-permission-v2/upsert-rbac", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json", Authorization: getToken() },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return error;
      }
    );
};

export let getDetailedFBReportCall = (groupId, userId, data) => {
  return fetch(constant.API_URL + "/api/feedback_report/generatefbreport/" + groupId + "/" + userId, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
    },
  })
    .then((result) => {
      console.log(result.blob());
      return result;
    })
    .catch((error) => {
      return error;
    });
};

export var getOrgBillingDetails = (orgid, invoiceno) => {
  // debugger;
  return fetch(constant.API_URL + "/api/billingreport/generateInvoice", {
    method: "POST",
    body: JSON.stringify({ orgid: orgid, invoiceno: invoiceno }),
    headers: { "Content-Type": "application/json", Authorization: getToken() },
  })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteOrganizationCall = (orgId) => {
  console.log("ORGID:", orgId);

  return fetch(constant.API_URL + "/api/organizations/" + orgId, {
    method: "DELETE",
    headers: { "Content-Type": "application/json", Authorization: getToken() },
  })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
};
