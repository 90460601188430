import React from 'react';
const signIn = React.lazy(() => import('./assesshub/Authentication/Signin'));
const signUp = React.lazy(() => import('./assesshub/Authentication/SignUp'));
const register = React.lazy(() => import('./assesshub/Authentication/Register'));
const resetPassword = React.lazy(() => import('./assesshub/Authentication/ResetPassword'));
const updatePassword = React.lazy(() => import('./assesshub/Authentication/UpdatePassword'));
const changePassword = React.lazy(() => import('./assesshub/Authentication/changePasswordOnSingup'));
const termsOfUse = React.lazy(() => import('./assesshub/Authentication/TermsOfUse'));
const AHFormView = React.lazy(() => import('./assesshub/custom_forms/FormView'));
const AHEditForm = React.lazy(() => import('./assesshub/custom_forms/EditUserForm'))

const route = [
    { path: '/auth/email-verify/:token', exact: true, name: 'Login', component: signIn },
    { path: '/auth/jfs/signin', exact: true, name: 'Login', component: signIn },
    { path: '/auth/signin', exact: true, name: 'Login', component: signIn },
    { path: '/auth/signup', exact: true, name: 'signup', component: signUp },
    { path: '/auth/register', exact: true, name: 'register', component: register },
    { path: '/auth/resetpassword', exact: true, name: 'resetpassword', component: resetPassword },
    { path: '/auth/change-password-on-signup', exact: true, name: 'resetpassword', component: changePassword },
    { path: '/auth/updatepassword/:id', exact: true, name: 'resetpassword', component: updatePassword },
    { path: '/auth/termsofuse', exact: true, name: 'termsofuse', component: termsOfUse },
    { path: '/forms/:orgid/:formName', exact: true, permission: true, name: 'customformview', component: AHFormView},
    { path: '/edituserform/:formName/:userId', exact: true, permission: true, name: 'userformedit', component: AHEditForm },
];

export default route;