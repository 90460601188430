import { serverConstants, localhostConstants } from "../utils/constants/serverConstants";

export var service = {};

service.API_URL = serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
  ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).url
  : localhostConstants.url;

// bot dashboard
service.BOT_API_URL = serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
  ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).botBackUrl
  : localhostConstants.botBackUrl;

service.BOT_WEB_URL = serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
  ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).botFrontUrl
  : localhostConstants.botFrontUrl;

// bot builder
service.BOT_BUILDER_API_URL = serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
  ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).botBuilderBackUrl
  : localhostConstants.botBuilderBackUrl;

service.BOT_BUILDER_WEB_URL = serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
  ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).botBuilderFrontUrl
  : localhostConstants.botBuilderFrontUrl;

service.Frontend_URL = serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
  ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).frontendUrl
  : localhostConstants.frontendUrl;

export default service;
