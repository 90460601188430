import React, { useEffect, useState } from "react";
import { getTicker } from "../../../service/organizationApiService";
import { HorizontalTicker } from "react-infinite-ticker";
import { Button } from "react-bootstrap";

const InvoiceTicker = () => {
  const [isVisible, setIsVisible] = useState(
    localStorage.getItem("ticker") === "true"
  );
  const [tickerItems, setTickerItems] = useState([]);
  const orgId = JSON.parse(localStorage.getItem("orgId"));

  const daysCompletedFrom = (dateString) => {
    const startDate = new Date(dateString);
    const today = new Date();

    startDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const oneDay = 1000 * 60 * 60 * 24;
    const daysCompleted = Math.floor((today - startDate) / oneDay);
    return `${daysCompleted} DAYS`.toLowerCase();
  };

  const handleLoading = () => {
    getTicker(orgId)
      .then((result) => {
        if (result.data && result.data.length > 0) {
          const items = [];
          let noteDisplayed = false;

          result.data.forEach((invoice) => {
            const dueDays = daysCompletedFrom(invoice.bill_generation_date);
            let color;

            const daysCount = parseInt(dueDays);
            if (daysCount < 20) {
              // color = "#8e44ad";
            } else if (daysCount >= 20 && daysCount < 30) {
              // color = "#8e44ad";
            } else {
              color = "#e74c3c";
            }

            if (!noteDisplayed) {
              items.push({
                message: `NOTE : Invoice # : ${
                  invoice.invoice_number
                } amounting to Rs. ${Number(
                  invoice.pending_amount
                ).toLocaleString()} is due since ${dueDays}`,
                color,
              });
              noteDisplayed = true;
            } else {
              items.push({
                message: `Invoice # : ${
                  invoice.invoice_number
                } amounting to Rs. ${Number(
                  invoice.pending_amount
                ).toLocaleString()} is due since ${dueDays}`,
                color,
              });
            }
          });

          setTickerItems(items);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error fetching ticker:", error);
      });
  };

  setInterval(() => {
    if (localStorage.getItem("ticker") != "true") {
      setIsVisible(false);
    }
  }, 500);

  useEffect(() => {
    handleLoading();
  }, [isVisible]);

  const closeBar = () => {
    localStorage.setItem("ticker", false);
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div>
        {tickerItems.length > 0 ? (
          <div
            className={`row`}
            style={{
              padding: "6px",
              color: "black",
              backgroundColor: `#f2f3f4`,
            }}
          >
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  flex: "1",
                  overflow: "hidden",
                }}
              >
                <HorizontalTicker duration={49500}>
                  <div style={{ display: "flex", whiteSpace: "nowrap" }}>
                    {tickerItems.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          marginLeft: "25px",
                          color: item.color,
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        {item.message}
                      </div>
                    ))}
                  </div>
                </HorizontalTicker>
              </div>
              <div style={{ flex: "0 0 1%" }}>
                <Button onClick={closeBar} size="sm" variant="light">
                  <i className="fa fa-times h-16" aria-hidden="true"></i>
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  );
};

export default InvoiceTicker;
